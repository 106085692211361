<template>
    <div>
    <b-card
    class="bg-image shadow border-0">
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems"
            :key="item.icon"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
  
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
</div>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
    },
    props: {
       dataList: Object,
    },
    computed: {
    statisticsItems() {
      if (this.dataList) {
        return [
          {
            icon: 'BarChart2Icon',
            color: 'light-success',
            title: this.dataList.total_payments,
            subtitle: 'Total Payments',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'BarChart2Icon',
            color: 'light-success',
            title: this.dataList.total_revenue,
            subtitle: 'Total Revenue',
            customClass: 'mb-2 mb-sm-0 ml-5',
          },
          {
            icon: 'BarChart2Icon',
            color: 'light-success',
            title: this.dataList.total_refunds,
            subtitle: 'Total Refunds',
            customClass: 'mb-2 mb-sm-0 ml-5',
          },
        ];
      }
      return [];
    }
  },

  }
  </script>
  <style>
    .bg-image {
    background-image: url('~@/assets/images/newDashboard/Background-1.png');
    background-size: cover;
    background-position: center;
    }
    .shadow{
    box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    }
</style>