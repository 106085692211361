<template>
  <div>
    <b-card class="bg-image shadow border-0">
      <b-row>
        <b-col md="6">
          <h3 style="font-weight: bold">Bookings w.r.t Status</h3>
          <br />
          <ul>
            <li class="second">Not Checked Out</li>
            <label>{{ notCheckedOut }}</label>
            <li class="third">Normal/Late Checkout</li>
            <label>{{ normalCheckout }}</label>
            <li class="third">Early Checkout</li>
            <label>{{ earlyCheckout }}</label>
            <li class="third">Cancelled</li>
            <label>{{ cancelled }}</label>
            <!-- <li class="third">Not Refunded</li> -->
            <!-- <label >20</label> -->
          </ul>
        </b-col>
        <b-col md="6">
          <vue-apex-charts
           v-if="dataList && Object.keys(dataList).length > 0"
            :key="chartKey"
            type="donut"
            height="450"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    dataList: Object,
  },
  mixins: [util],
  data() {
    return {
        chartKey: 0,
      donutChart: {
        series: [],
        chartOptions: {
          labels: [],
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Total",
                    formatter: (w) => {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
          labels: [
            "Not Checked Out",
            "Normal/Late Checkout",
            "Early Checkout",
            "Cancelled",
          ],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
  
  },
  methods: {
    updateChart() {
        this.chartKey++;
    if (this.dataList) {
      this.donutChart.series = [
        // this.dataList.pending_and_unpaid,
        // this.dataList.cancelled_and_unpaid,
        this.dataList.not_checked_out,
        this.dataList.normal_or_late_checkout,
        this.dataList.early_checkout,
        this.dataList.cancelled,
      ];
    }
  },
  },

  computed: {
    // pendingUnpaid() {
    //   return this.dataList ? this.dataList.pending_and_unpaid : 0;
    // },
    // cancelledUnpaid() {
    //   return this.dataList ? this.dataList.cancelled_and_unpaid : 0;
    // },
    // confirmedPaid() {
    //   return this.dataList ? this.dataList.confirmed_and_paid : 0;
    // },
    // cancelledRefundPending() {
    //   return this.dataList ? this.dataList.cancelled_and_refund_pending : 0;
    // },
    // cancelledRefunded() {
    //   return this.dataList ? this.dataList.cancelled_and_refunded : 0;
    // },
    notCheckedOut() {
      return this.dataList ? this.dataList.not_checked_out : 0;
    },
    normalCheckout() {
      return this.dataList ? this.dataList.normal_or_late_checkout : 0;
    },
    earlyCheckout() {
      return this.dataList ? this.dataList.early_checkout : 0;
    },
    cancelled() {
      return this.dataList ? this.dataList.cancelled : 0;
    },

  },
  watch: {
  dataList: {
    deep: true,
    immediate: true,
    handler(newVal) {
      this.updateChart();
      this.chartKey++;
    }
  }
  }
};
</script>
<style scoped>
.bg-image {
  background-image: url("~@/assets/images/newDashboard/Background-1.png");
  background-size: cover;
  background-position: center;
}
.shadow {
  box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -webkit-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -moz-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
}
.first::marker {
  color: rgb(14, 159, 217);
  font-size: 25px;
}
.second::marker {
  color: rgb(233, 116, 116);
  font-size: 25px;
}
.third::marker {
  color: rgb(85, 205, 162);
  font-size: 25px;
}

li {
  font-size: 18px;
}
label {
  font-size: 18px;
  font-weight: bolder;
  color: black;
}
</style>